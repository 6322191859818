import React from 'react';
import ContentLoader from 'react-content-loader';
import { LoadingWrapper, Individual } from './elements';

const Loading = () => (
  <ContentLoader
    ariaLabel="Loading templates"
    width={613}
    height={260}
    primaryColor="#242424"
    secondaryColor="#1A1A1A"
  >
    <polygon points="0 0 299.623 0 299.623 17 0 17" />
    <path d="M0,43 C0,41.8954 0.895431,41 2,41 L29.4359,41 C30.5405,41 31.4359,41.8954 31.4359,43 L31.4359,71 C31.4359,72.1046 30.5405,73 29.4359,73 L2,73 C0.89543,73 0,72.1046 0,71 L0,43 Z" />
    <polygon points="160.127 41 54.0304 41 54.0304 53 160.127 53" />
    <polygon points="54.0304 61 106.096 61 106.096 73 54.0304 73" />
    <polygon points="278.011 61 189.598 61 189.598 73 278.011 73" />
    <path d="M0,99 C0,97.8954 0.895431,97 2,97 L29.4359,97 C30.5405,97 31.4359,97.8954 31.4359,99 L31.4359,127 C31.4359,128.105 30.5405,129 29.4359,129 L2,129 C0.89543,129 0,128.105 0,127 L0,99 Z" />
    <polygon points="160.127 97 54.0304 97 54.0304 109 160.127 109" />
    <polygon points="54.0304 117 106.096 117 106.096 129 54.0304 129" />
    <polygon points="278.011 117 189.598 117 189.598 129 278.011 129" />
    <path d="M334.989,43 C334.989,41.8954 335.884,41 336.989,41 L364.425,41 C365.529,41 366.425,41.8954 366.425,43 L366.425,71 C366.425,72.1046 365.529,73 364.425,73 L336.989,73 C335.884,73 334.989,72.1046 334.989,71 L334.989,43 Z" />
    <polygon points="495.115 41 389.019 41 389.019 53 495.115 53" />
    <polygon points="389.019 61 441.085 61 441.085 73 389.019 73" />
    <polygon points="613 61 524.587 61 524.587 73 613 73" />
    <path d="M334.989,99 C334.989,97.8954 335.884,97 336.989,97 L364.425,97 C365.529,97 366.425,97.8954 366.425,99 L366.425,127 C366.425,128.105 365.529,129 364.425,129 L336.989,129 C335.884,129 334.989,128.105 334.989,127 L334.989,99 Z" />
    <polygon points="495.115 97 389.019 97 389.019 109 495.115 109" />
    <polygon points="389.019 117 441.085 117 441.085 129 389.019 129" />
    <polygon points="613 117 524.587 117 524.587 129 613 129" />
    <path d="M0,153 C0,151.8954 0.895431,151 2,151 L29.4359,151 C30.5405,151 31.4359,151.8954 31.4359,153 L31.4359,181 C31.4359,182.1046 30.5405,183 29.4359,183 L2,183 C0.89543,183 0,182.1046 0,181 L0,153 Z" />
    <polygon points="160.127 151 54.0304 151 54.0304 163 160.127 163" />
    <polygon points="54.0304 171 106.096 171 106.096 183 54.0304 183" />
    <polygon points="278.011 171 189.598 171 189.598 183 278.011 183" />
    <path d="M0,209 C0,207.8954 0.895431,207 2,207 L29.4359,207 C30.5405,207 31.4359,207.8954 31.4359,209 L31.4359,237 C31.4359,238.105 30.5405,239 29.4359,239 L2,239 C0.89543,239 0,238.105 0,237 L0,209 Z" />
    <polygon points="160.127 207 54.0304 207 54.0304 219 160.127 219" />
    <polygon points="54.0304 227 106.096 227 106.096 239 54.0304 239" />
    <polygon points="278.011 227 189.598 227 189.598 239 278.011 239" />
    <path d="M334.989,153 C334.989,151.8954 335.884,151 336.989,151 L364.425,151 C365.529,151 366.425,151.8954 366.425,153 L366.425,181 C366.425,182.1046 365.529,183 364.425,183 L336.989,183 C335.884,183 334.989,182.1046 334.989,181 L334.989,153 Z" />
    <polygon points="495.115 151 389.019 151 389.019 163 495.115 163" />
    <polygon points="389.019 171 441.085 171 441.085 183 389.019 183" />
    <polygon points="613 171 524.587 171 524.587 183 613 183" />
    <path d="M334.989,209 C334.989,207.8954 335.884,207 336.989,207 L364.425,207 C365.529,207 366.425,207.8954 366.425,209 L366.425,237 C366.425,238.105 365.529,239 364.425,239 L336.989,239 C335.884,239 334.989,238.105 334.989,237 L334.989,209 Z" />
    <polygon points="495.115 207 389.019 207 389.019 219 495.115 219" />
    <polygon points="389.019 227 441.085 227 441.085 239 389.019 239" />
    <polygon points="613 227 524.587 227 524.587 239 613 239" />
  </ContentLoader>
);

export const Loader = () => (
  <LoadingWrapper>
    <Individual>
      <Loading />
    </Individual>
    <Individual>
      <Loading />
    </Individual>
  </LoadingWrapper>
);
