"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.collectAllSettings = void 0;
const scopeMapper_1 = require("./scopeMapper");
const scopeScore_1 = require("./scopeScore");
const transformSettings_1 = require("./transformSettings");
const minify_1 = require("./minify");
exports.collectAllSettings = tokenColors => {
    const output = {};
    tokenColors.forEach(({ scope, settings }) => {
        // We only care about colouring here
        if (!settings.foreground && !settings.fontStyle) {
            return;
        }
        const normScope = typeof scope === 'string' ? [scope] : scope;
        // Return when no input scopes are present
        if (!normScope || !normScope.length) {
            return;
        }
        normScope.forEach(scopeName => {
            const mappedScope = scopeMapper_1.mapScope(scopeName);
            // Return when no mapping scope has been returned
            if (!mappedScope) {
                return;
            }
            if (output[mappedScope] === undefined) {
                output[mappedScope] = [];
            }
            output[mappedScope].push({
                scope: scopeName,
                settings,
            });
        });
    });
    const styles = Object.keys(output).map(mappedScope => {
        const matchesArr = output[mappedScope];
        // Get score for each match
        const scored = matchesArr.map(match => {
            const score = scopeScore_1.getScoreForScope(match.scope, mappedScope);
            return {
                score,
                scope: mappedScope,
                settings: transformSettings_1.transformSettings(match.settings),
            };
        });
        // Sort by score asc
        const sorted = scored.sort((a, b) => b.score - a.score);
        // Return highest-scored one
        return sorted[0];
    });
    const themeStyles = minify_1.minify(styles);
    return {
        styles: themeStyles,
    };
};
