import React from 'react';
import IconBase from 'react-icons/IconBase';

export const StackbitIcon = props => (
  <IconBase viewBox="0 0 32 32" {...props}>
    <path
      d="M31.9879 13.7232C32.0918 12.5911 31.5206 11.428 30.4612 10.8152L17.6663 3.43104C16.7316 2.85983 15.5684 2.85983 14.6128 3.41027L1.56854 10.7944C0.436507 11.428 -0.0827749 12.5911 0.0106931 13.7232V18.646C-0.0620036 19.83 0.509201 20.9827 1.58931 21.5747L14.6336 28.7097C15.5684 29.2289 16.6796 29.2289 17.6143 28.7097L30.4198 21.5747C31.4999 20.9827 32.0919 19.7988 31.988 18.646L31.9879 13.7232ZM29.433 19.7572L16.6276 26.8922C16.3368 27.0687 15.9629 27.0687 15.6721 26.8922L2.62777 19.7572C2.15003 19.5079 1.96309 18.8952 2.23311 18.4279C2.47198 17.9605 3.09512 17.7632 3.56248 18.0333L16.1395 24.8981L28.4672 18.0333C28.9346 17.7633 29.5265 17.9294 29.7966 18.4279C30.0665 18.8951 29.9005 19.4767 29.433 19.7572ZM29.7966 13.9723C29.5265 14.4396 28.9138 14.6058 28.4465 14.3359L16.1395 7.22169L5.08916 13.4739L16.6276 19.996C17.095 20.266 17.2715 20.8892 17.0014 21.3565C16.7315 21.8239 16.1083 21.9901 15.641 21.72L2.59661 14.3359C1.93194 13.9412 1.93194 12.9857 2.59661 12.6118L15.641 5.22764C15.9629 5.05109 16.3368 5.05109 16.6276 5.22764L29.433 12.6118C29.9005 12.882 30.0665 13.4946 29.7966 13.9723Z"
      fill="white"
    />
  </IconBase>
);
