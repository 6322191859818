import React from 'react';
import IconBase from 'react-icons/IconBase';

export const InfoIcon = ({ ...props }) => (
  <IconBase viewBox="0 0 12 12" {...props}>
    <circle cx="6" cy="6" r="5.5" fill="white" stroke="#242424" />
    <path
      d="M6.65217 8.65049C6.45894 8.8835 6.21739 9 5.92754 9C5.63768 9 5.40902 8.92557 5.24155 8.7767C5.08052 8.62783 5 8.43366 5 8.19417C5 8.11003 5.05153 7.75728 5.15459 7.13592L5.47343 5.39806H5.06763L5.08696 5.2233H5.50242C5.97907 5.2233 6.33011 5.20388 6.55556 5.16505L6.69082 5.13592L6.02415 8.80583C6.21739 8.78641 6.37842 8.69579 6.50725 8.53398L6.65217 8.65049ZM5.80193 4.20388C5.66667 4.06796 5.59903 3.90291 5.59903 3.70874C5.59903 3.51456 5.66667 3.34951 5.80193 3.21359C5.9372 3.0712 6.10145 3 6.29469 3C6.48792 3 6.65217 3.0712 6.78744 3.21359C6.92915 3.34951 7 3.51456 7 3.70874C7 3.90291 6.92915 4.06796 6.78744 4.20388C6.65217 4.33981 6.48792 4.40777 6.29469 4.40777C6.10145 4.40777 5.9372 4.33981 5.80193 4.20388Z"
      fill="#151515"
    />
  </IconBase>
);
