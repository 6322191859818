"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseWrapper = exports.BaseThumbY = exports.BaseThumbX = exports.BaseTrackY = exports.BaseTrackX = exports.BaseScroller = exports.Container = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const react_scrollbars_custom_1 = require("react-scrollbars-custom");
exports.Container = styled_components_1.default(react_scrollbars_custom_1.Scrollbar) `
  flex: 1 1 auto;
`;
exports.BaseScroller = styled_components_1.default.span `
  @media screen and (max-width: 800px) {
    padding-bottom: 40px;
  }
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;
const thumb = ({ position = true } = {}) => styled_components_1.css `
  ${position ? `width` : `height`}: 0.8rem;
  border-radius: 50px;
  border: 0.5px solid rgba(255, 255, 255, 0.8);
  background: rgba(36, 36, 36, 0.4);
  box-sizing: border-box;
  opacity: 0.4;
  cursor: pointer;
  transition: opacity 0.1s ease-in-out;

  &:hover,
  &:active {
    opacity: 1;
  }
`;
const track = ({ position = true } = {}) => styled_components_1.css `
  ${position ? `top` : `bottom`}: 0;
  ${position ? `right` : `left`}: 0;
  display: flex;
  ${position ? `justify-content` : `align-items`}: center;
  ${position ? `width: 0.5rem !important` : `height: 0.5rem !important`};
  padding: 0.25rem;
  background-color: transparent !important;
  cursor: pointer;
`;
exports.BaseTrackX = styled_components_1.default.span `
  ${track({ position: false })}
`;
exports.BaseTrackY = styled_components_1.default.span `
  ${track({ position: true })}

  &:after {
    content: '';
    position: absolute;
    bottom: -1rem;
    right: 0;
    width: 1rem;
    height: 1rem;
    background-color: rgba(255, 255, 255, 0.35);
    user-select: none;
    speak: none;
  }
`;
exports.BaseThumbX = styled_components_1.default.span `
  ${thumb({ position: false })}
`;
exports.BaseThumbY = styled_components_1.default.span `
  ${thumb({ position: true })}
`;
exports.BaseWrapper = styled_components_1.default.span `
  /* We want to overlay the scrollbar */
  right: 0 !important;
  bottom: 0 !important;
`;
