import React from 'react';

export const CardIcon = () => (
  <svg width={77} height={67} fill="none">
    <path
      d="M28.724 0H1.591A1.59 1.59 0 000 1.59v63.82C0 66.287.712 67 1.59 67h73.82A1.59 1.59 0 0077 65.41V9.818a1.59 1.59 0 00-1.59-1.59H38.5L29.787.406A1.59 1.59 0 0028.724 0z"
      fill="#6CC7F6"
    />
    <path
      d="M52.854 29.169c-1.431-2.513-3.372-4.503-5.823-5.97C44.579 21.734 41.903 21 39 21c-2.903 0-5.58.733-8.031 2.2-2.452 1.466-4.392 3.456-5.823 5.969C23.716 31.68 23 34.425 23 37.4c0 3.573 1.017 6.787 3.052 9.641 2.035 2.855 4.663 4.83 7.885 5.926.375.072.653.022.834-.149.18-.17.27-.384.27-.64l-.01-1.153c-.007-.727-.01-1.36-.01-1.9l-.48.084c-.305.058-.69.082-1.156.075a8.595 8.595 0 01-1.448-.15 3.197 3.197 0 01-1.396-.64 2.708 2.708 0 01-.916-1.313l-.209-.491a5.365 5.365 0 00-.656-1.09c-.299-.398-.6-.668-.906-.81l-.146-.108a1.542 1.542 0 01-.27-.256c-.084-.1-.146-.2-.188-.3-.042-.099-.007-.18.104-.245.111-.064.312-.096.604-.096l.417.064c.277.057.621.228 1.031.513.41.284.746.654 1.01 1.11.32.584.705 1.029 1.157 1.335.45.306.906.459 1.364.459a5.78 5.78 0 001.188-.107c.333-.07.645-.178.937-.32.125-.955.465-1.688 1.02-2.2a13.95 13.95 0 01-2.135-.385 8.383 8.383 0 01-1.958-.833 5.644 5.644 0 01-1.677-1.43c-.444-.57-.81-1.317-1.094-2.243-.284-.925-.427-1.993-.427-3.203 0-1.723.55-3.19 1.646-4.4-.514-1.295-.465-2.747.146-4.356.403-.128 1-.032 1.792.289.791.32 1.371.594 1.74.822.367.227.663.42.885.576a14.452 14.452 0 014-.555c1.375 0 2.708.185 4 .555l.792-.512a11.09 11.09 0 011.916-.94c.736-.284 1.299-.363 1.688-.234.625 1.608.68 3.06.166 4.356 1.098 1.21 1.646 2.677 1.646 4.4 0 1.21-.142 2.28-.427 3.213-.284.933-.652 1.68-1.103 2.242a5.857 5.857 0 01-1.688 1.42 8.403 8.403 0 01-1.959.834c-.632.17-1.343.299-2.135.384.722.64 1.083 1.652 1.083 3.033v4.505c0 .256.087.47.26.64.174.171.449.221.824.15 3.222-1.096 5.85-3.072 7.885-5.926S55 40.974 55 37.4c0-2.975-.716-5.719-2.146-8.231z"
      fill="#000"
      fillOpacity={0.4}
    />
  </svg>
);

export const ListIcon = () => (
  <svg width={30} height={27} fill="none">
    <path
      d="M11.242 0H.475A.475.475 0 000 .475v26.05c0 .262.213.475.475.475h29.05a.475.475 0 00.475-.475V3.791a.475.475 0 00-.475-.475H15L11.565.126A.475.475 0 0011.242 0z"
      fill="#6CC7F6"
    />
    <path
      d="M21.061 10.574a7.06 7.06 0 00-2.547-2.612A6.719 6.719 0 0015 7a6.72 6.72 0 00-3.514.962 7.059 7.059 0 00-2.547 2.612A7.155 7.155 0 008 14.175c0 1.564.445 2.97 1.335 4.219.89 1.248 2.04 2.113 3.45 2.592.164.031.286.01.365-.065a.37.37 0 00.118-.28l-.005-.505a86.143 86.143 0 01-.004-.831l-.21.037a2.612 2.612 0 01-.506.033 3.756 3.756 0 01-.633-.066 1.399 1.399 0 01-.61-.28 1.184 1.184 0 01-.402-.574l-.09-.215a2.347 2.347 0 00-.288-.477c-.13-.174-.263-.292-.396-.355l-.064-.047a.678.678 0 01-.119-.112.516.516 0 01-.082-.13c-.018-.044-.003-.08.046-.108.049-.028.137-.042.264-.042l.182.028c.122.025.272.1.452.224.179.125.326.287.442.486.14.255.308.45.506.584.197.134.396.2.596.2.2 0 .374-.015.52-.046.146-.031.282-.078.41-.14.055-.418.204-.738.447-.962a6.104 6.104 0 01-.935-.169 3.671 3.671 0 01-.856-.364 2.47 2.47 0 01-.734-.626c-.194-.25-.354-.576-.479-.981a4.775 4.775 0 01-.186-1.402c0-.753.24-1.395.72-1.924-.225-.567-.204-1.202.064-1.906.176-.056.437-.014.783.126.347.14.6.26.761.36.162.1.29.183.388.252a6.323 6.323 0 011.75-.243c.601 0 1.185.081 1.75.243l.346-.224c.237-.15.517-.287.839-.411.322-.125.568-.16.738-.103.274.704.298 1.339.073 1.906.48.53.72 1.17.72 1.925 0 .529-.062.998-.187 1.405-.124.409-.285.735-.482.982a2.564 2.564 0 01-.739.62c-.295.17-.58.29-.857.365a6.1 6.1 0 01-.934.169c.316.28.474.722.474 1.326v1.971c0 .113.038.206.114.28.076.075.196.097.36.066 1.41-.48 2.56-1.344 3.45-2.593.89-1.248 1.335-2.654 1.335-4.218a7.16 7.16 0 00-.939-3.601z"
      fill="#000"
      fillOpacity={0.45}
    />
  </svg>
);
