import React, { FunctionComponent, SVGProps } from 'react';

type IconProps = SVGProps<SVGSVGElement>;

export const DiscordIcon: FunctionComponent<IconProps> = props => (
  <svg width={32} height={32} fill="none" viewBox="0 0 32 32" {...props}>
    <g clipPath="url(#clip0)">
      <path
        fill="currentColor"
        d="M22.099 9c1.05 0 1.898.805 1.948 1.753V26l-2-1.61-1.1-.948-1.199-.99.501 1.562H9.756c-1.048 0-1.9-.755-1.9-1.754V10.757c0-.948.853-1.755 1.903-1.755h12.334L22.1 9zm-4.574 4.025h-.022l-.151.142c1.55.425 2.3 1.089 2.3 1.089a8.468 8.468 0 00-2.8-.806c-.65-.095-1.3-.045-1.85 0h-.15c-.35 0-1.098.142-2.1.521-.349.144-.549.238-.549.238s.749-.71 2.4-1.089l-.101-.095s-1.25-.046-2.6.9c0 0-1.349 2.226-1.349 4.972 0 0 .748 1.232 2.798 1.28 0 0 .3-.378.602-.71-1.151-.332-1.6-.995-1.6-.995s.1.047.25.141h.046c.022 0 .032.011.044.022v.004c.012.011.023.021.045.021.247.097.494.192.695.284a6.37 6.37 0 001.346.38 8.094 8.094 0 002.4 0c.448-.096.897-.19 1.345-.38.291-.141.65-.283 1.044-.522 0 0-.448.663-1.648.995.247.33.594.708.594.708 2.052-.042 2.848-1.275 2.893-1.223 0-2.74-1.357-4.972-1.357-4.972-1.222-.86-2.366-.893-2.567-.893l.042-.014v.002zm.126 3.126a.948.948 0 110 1.895.949.949 0 01-.95-.945.951.951 0 01.95-.947v-.003zm-3.396 0a.947.947 0 11-.003 1.895.95.95 0 01-.95-.945c0-.524.427-.947.95-.947l.003-.003z"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="currentColor" d="M0 0H32V32H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const TwitterIcon: FunctionComponent<IconProps> = props => (
  <svg width={32} height={32} fill="none" viewBox="0 0 32 32" {...props}>
    <g clipPath="url(#clip0)">
      <path
        fill="currentColor"
        d="M24 10.82a6.557 6.557 0 01-1.885.517 3.288 3.288 0 001.443-1.816 6.575 6.575 0 01-2.085.796 3.286 3.286 0 00-5.594 2.993 9.319 9.319 0 01-6.765-3.429 3.287 3.287 0 001.015 4.383 3.268 3.268 0 01-1.486-.41c-.036 1.52 1.054 2.943 2.633 3.26a3.289 3.289 0 01-1.483.055 3.285 3.285 0 003.067 2.28A6.6 6.6 0 018 20.809a9.293 9.293 0 005.032 1.474c6.095 0 9.538-5.147 9.33-9.764A6.683 6.683 0 0024 10.82z"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="currentColor" d="M0 0H32V32H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const GithubIcon: FunctionComponent<IconProps> = props => (
  <svg width={32} height={32} fill="none" viewBox="0 0 32 32" {...props}>
    <g clipPath="url(#clip0)">
      <path
        fill="currentColor"
        d="M22.927 12.365a8.068 8.068 0 00-2.912-2.984A7.678 7.678 0 0016 8.28c-1.451 0-2.79.366-4.016 1.1a8.067 8.067 0 00-2.911 2.984A8.177 8.177 0 008 16.481c0 1.787.509 3.394 1.526 4.821 1.017 1.427 2.332 2.415 3.943 2.963.187.036.326.01.416-.075a.423.423 0 00.136-.32l-.006-.576a97.12 97.12 0 01-.005-.95l-.24.042c-.152.029-.345.04-.578.037a4.301 4.301 0 01-.723-.074 1.6 1.6 0 01-.698-.32 1.354 1.354 0 01-.459-.657l-.104-.246a2.68 2.68 0 00-.328-.544c-.15-.2-.3-.335-.453-.406l-.073-.053a.773.773 0 01-.135-.129.592.592 0 01-.094-.15c-.02-.05-.004-.09.052-.122.056-.032.156-.048.302-.048l.208.032c.14.028.311.114.516.256.205.143.373.328.505.555.16.292.352.515.578.668.226.153.453.23.683.23.229 0 .427-.019.593-.054.167-.036.323-.09.469-.16.062-.477.233-.844.51-1.1a6.98 6.98 0 01-1.067-.192 4.192 4.192 0 01-.98-.417 2.82 2.82 0 01-.838-.715c-.222-.285-.405-.659-.547-1.122-.142-.462-.213-.996-.213-1.601 0-.862.274-1.595.823-2.2-.257-.648-.233-1.374.072-2.178.202-.064.5-.016.896.144.396.16.686.297.87.411.184.114.332.21.443.289a7.227 7.227 0 012-.278c.687 0 1.354.092 2 .277l.396-.256c.27-.17.59-.327.958-.47.368-.142.65-.181.844-.117.312.804.34 1.53.083 2.178.549.605.823 1.339.823 2.2 0 .605-.071 1.14-.213 1.607-.143.466-.326.84-.552 1.121a2.929 2.929 0 01-.844.71 4.198 4.198 0 01-.98.416c-.315.086-.671.15-1.067.193.361.32.542.826.542 1.516v2.253a.43.43 0 00.13.32c.087.085.224.11.411.075 1.611-.548 2.926-1.536 3.943-2.963S24 18.268 24 16.48c0-1.488-.358-2.86-1.073-4.116z"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="currentColor" d="M0 0H32V32H0z" />
      </clipPath>
    </defs>
  </svg>
);
