import React from 'react';

export const CodeAnywhere = props => (
  <svg
    width="200"
    height="100"
    viewBox="0 0 200 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="101" cy="46" r="14" fill="white" />
    <path
      d="M142.5 48.5C142.5 53.8632 138.031 58.8603 130.479 62.5464C122.961 66.2161 112.539 68.5 101 68.5C89.4612 68.5 79.0393 66.2161 71.5208 62.5464C63.9689 58.8603 59.5 53.8632 59.5 48.5C59.5 43.1368 63.9689 38.1397 71.5208 34.4536C79.0393 30.7839 89.4612 28.5 101 28.5C112.539 28.5 122.961 30.7839 130.479 34.4536C138.031 38.1397 142.5 43.1368 142.5 48.5Z"
      stroke="#8F8F8F"
    />
    <path
      d="M160.75 50C160.75 57.5761 154.105 64.5138 143.175 69.5721C132.266 74.6207 117.179 77.75 100.5 77.75C83.8212 77.75 68.7336 74.6207 57.825 69.5721C46.8955 64.5138 40.25 57.5761 40.25 50C40.25 42.4239 46.8955 35.4862 57.825 30.4279C68.7336 25.3793 83.8212 22.25 100.5 22.25C117.179 22.25 132.266 25.3793 143.175 30.4279C154.105 35.4862 160.75 42.4239 160.75 50Z"
      stroke="#444444"
      strokeWidth="0.5"
    />
    <circle cx="122" cy="33" r="5" fill="#8F8F8F" />
    <circle cx="138" cy="28" r="3" fill="#444444" />
  </svg>
);
