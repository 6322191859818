import React from 'react';
import { Stack } from '@codesandbox/components';
import css from '@styled-system/css';

export const InvitationIcon = ({ read, ...props }) => (
  <Stack
    align="center"
    justify="center"
    css={css({
      borderRadius: '50%',
      width: 18,
      height: 18,
      background:
        ' linear-gradient(225deg, rgba(0, 0, 0, 0) 13.89%, rgba(0, 0, 0, 0.2) 83.33%), #343434',
    })}
    {...props}
  >
    <svg width={14} height={14} fill="none" viewBox="0 0 14 14">
      <path
        fill={read ? '#C4C4C4' : '#fff'}
        fillRule="evenodd"
        d="M5.037 2.654c.012.02.023.04.032.061 1.462-.472 3.145.182 3.99 1.645l.697 1.208 2.406 1.418c.46.271.483.942.04 1.197l-3.548 2.05-3.55 2.048c-.442.256-1.011-.099-1.016-.633l-.025-2.793-.698-1.208c-.844-1.463-.569-3.248.571-4.278a.752.752 0 01-.037-.058c-.193-.334-.094-.751.22-.933.315-.181.726-.058.918.276zM7.62 12.44c-.421.244-.93.213-1.335-.034l1.973-1.14c.011.476-.216.931-.638 1.174z"
        clipRule="evenodd"
      />
    </svg>
  </Stack>
);

export const AcceptedIcon = ({ read, ...props }) => (
  <Stack
    align="center"
    justify="center"
    css={css({
      borderRadius: '50%',
      width: 18,
      height: 18,
      background:
        'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%), #5BC266;',
    })}
    {...props}
  >
    <svg width={9} height={9} fill="none" viewBox="0 0 9 9">
      <path
        fill={read ? '#C4C4C4' : '#fff'}
        fillRule="evenodd"
        d="M4.425 1.79c0 .393-.14.78-.304 1.075-.038.067.03.18.097.293.074.125.149.25.085.314-.176.175-.59.154-.927.13-.22.192-.486.305-.772.305-.322 0-.618-.143-.853-.382l-.192.09C1.06 3.55.752 2.728.871 1.781.99.834 1.49.12 1.99.186c.114.024.217.056.311.094.215-.177.47-.28.742-.28.763 0 1.382.802 1.382 1.79zM.021 7.068h3.782A2.648 2.648 0 104.078 5c-.37-.229-.884-.386-1.593-.402C-.4 4.534.02 7.067.02 7.067zm7.867-1.02H6.63V4.73h-.577v1.318h-1.26v.543h1.26v1.427h.577V6.59h1.26v-.543z"
        clipRule="evenodd"
      />
    </svg>
  </Stack>
);

export const TeamIcon = ({ read, ...props }) => (
  <Stack
    align="center"
    justify="center"
    css={css({
      borderRadius: '50%',
      width: 18,
      height: 18,
      background:
        'linear-gradient(225deg, rgba(0, 0, 0, 0) 13.89%, rgba(0, 0, 0, 0.2) 83.33%), #BF5AF2',
    })}
    {...props}
  >
    <svg width={12} height={8} fill="none" viewBox="0 0 12 8">
      <path
        fill={read ? '#C4C4C4' : '#fff'}
        fillRule="evenodd"
        d="M2.39 4.144c.832 0 1.508-.8 1.526-1.719a.372.372 0 00.067-.182.349.349 0 00-.076-.265c-.043-.844-.26-1.741-1.15-1.85h-.01C2.487.096 1.9.025 1.78.38.967.28.866 1.143.86 1.855c-.094.058-.142.195-.112.336a.358.358 0 00.116.2c.002.934.685 1.753 1.526 1.753zM3 7c0-.782 0-1.5 1-2C3 4 .173 3.845 0 7h3zM9.746 4.152c-.843.005-1.532-.812-1.537-1.748v-.142a.364.364 0 01-.086-.194c-.018-.129.028-.249.11-.309C8.3.991 8.548.242 9.356.14h.01c.259-.034.846-.108.969.246.897-.114.934.946.93 1.684.066.066.1.178.079.297a.37.37 0 01-.087.183c-.069.867-.717 1.599-1.512 1.603zm-.898 2.594c.018-.535.029-.871-.734-1.646C8.562 4.352 12 4 12 7.024H8.842c0-.1.003-.191.006-.278z"
        clipRule="evenodd"
      />
      <path
        fill={read ? '#C4C4C4' : '#fff'}
        fillRule="evenodd"
        d="M7.912 2.09c0 .399-.142.792-.31 1.092-.037.068.031.183.1.298.075.126.15.253.086.318-.179.179-.6.157-.941.132-.224.196-.495.31-.785.31-.327 0-.628-.144-.867-.387L5 3.944c-.507-.067-.82-.901-.699-1.864.12-.962.63-1.687 1.137-1.62.116.025.221.057.316.095.218-.18.477-.284.754-.284.775 0 1.404.815 1.404 1.82zM3.458 7.25h5.066S8.89 4.805 5.96 4.74c-2.93-.063-2.502 2.51-2.502 2.51z"
        clipRule="evenodd"
      />
    </svg>
  </Stack>
);

export const CommentIcon = ({ read, ...props }) => (
  <Stack
    align="center"
    justify="center"
    css={css({
      borderRadius: '50%',
      width: 18,
      height: 18,
      background:
        'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%), #0971F1',
    })}
    {...props}
  >
    <svg
      width="13"
      height="8"
      viewBox="0 0 13 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.96046e-05 3.4046C2.00272e-05 3.39794 0 3.39128 0 3.38462C0 1.51535 1.56517 0 3.49591 0C5.42665 0 6.99182 1.51535 6.99182 3.38462C6.99182 5.25387 5.42668 6.76921 3.49597 6.76924H5.96046e-05V3.4046Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.14282 6.45618C7.0503 5.72243 7.62765 4.61879 7.62765 3.38458C7.62765 2.96807 7.5619 2.56643 7.4399 2.18886C7.87684 1.96986 8.37297 1.84616 8.89894 1.84616C10.6542 1.84616 12.077 3.22374 12.077 4.92308C12.077 4.93881 12.0769 4.95451 12.0767 4.97018V8.00004H8.89858V8.00001C7.7201 7.99988 6.69148 7.37874 6.14282 6.45618Z"
          fill="white"
        />
      </g>
    </svg>
  </Stack>
);
