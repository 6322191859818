import React from 'react';
import IconBase from 'react-icons/IconBase';

export const UploadIcon = props => (
  <IconBase viewBox="0 0 32 32" {...props}>
    <path
      d="M24.8634 7.62269C27.1014 7.84325 28.875 9.64481 29.0522 11.8953C30.8001 12.7708 32 14.5786 32 16.6667C32 19.6122 29.6122 22 26.6667 22H19.3333V16.4074L22.5379 19.8408L25.4621 17.1116L17.3333 8.40215L9.20456 17.1116L12.1288 19.8408L15.3333 16.4074V22H4V21.9527C1.73847 21.6293 0 19.6843 0 17.3333C0 15.3481 1.23967 13.6523 2.98715 12.978C2.95167 12.7461 2.93327 12.5085 2.93327 12.2666C2.93327 9.68931 5.02261 7.59998 7.59993 7.59998C8.06739 7.59998 8.5188 7.66871 8.94459 7.79661C10.0404 4.43178 13.2029 2 16.9334 2C20.5993 2 23.7167 4.3483 24.8634 7.62269Z"
      fill="currentColor"
    />
    <path d="M16 22H18.6667V30H16V22Z" fill="currentColor" />
  </IconBase>
);
