import React from 'react';
import IconBase from 'react-icons/IconBase';

export const NewIcon = props => (
  <IconBase viewBox="0 0 16 16" {...props}>
    <path
      fill="#fff"
      d="M7.111 9.263H.89v5.053c0 .93.796 1.684 1.778 1.684H7.11V9.263zM8.889 16h4.444c.982 0 1.778-.754 1.778-1.684V9.263H8.89V16z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M8.208 3.694h-.172a2.161 2.161 0 01-.41-.029 8.02 8.02 0 01-.978-.233 31.435 31.435 0 01-2.128-.73c-.71-.267-1.097-1.006-.923-1.693C3.838.055 5.01-.327 5.842.323c.61.478 1.343 1.07 1.891 1.578.173.159.334.316.47.463.136-.147.298-.304.47-.463C9.22 1.394 9.954.8 10.564.323c.83-.65 2.004-.268 2.245.686.174.687-.214 1.426-.922 1.694a31.43 31.43 0 01-2.129.729 8.02 8.02 0 01-.978.233c-.14.022-.281.035-.41.03-.045-.002-.162 0-.162 0zM4.801 1.908c.59.223 1.383.509 2.06.707.202.059.387.108.551.146a9.659 9.659 0 00-.265-.254A31.676 31.676 0 005.314.979c-.312-.244-.769-.112-.866.272a.561.561 0 00.353.657zm6.804 0c-.59.223-1.383.509-2.06.707-.202.059-.387.108-.551.146.078-.079.167-.164.266-.254A31.66 31.66 0 0111.092.979c.312-.244.769-.112.866.272a.561.561 0 01-.353.657z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M1.778 4.21C.796 4.21 0 4.966 0 5.896v1.684h7.111V4.211H1.778zM8.889 4.21V7.58H16V5.895c0-.93-.796-1.684-1.778-1.684H8.89z"
    />
  </IconBase>
);
